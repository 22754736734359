import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '@components/Spacer'
import { Heading1 } from '@components/TextStyles'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { Heading3, TextBodySmall } from '@styles/vars/textStyles.style'
import {
  TeamListWrapper,
  TeamMember,
  TeamImage,
  TeamContent,
  CarouselControls,
} from './index.style'
import IconButton from '@components/IconButton'
import IconCarouselArrow from '@svgs/IconCarouselArrow'

const Team = ({ members, title }) => {
  const blockLength = members.length
  const [activeIndex, setActiveIndex] = useState(0)

  const previous = () => {
    setActiveIndex(activeIndex - 1)
  }

  const next = () => {
    setActiveIndex(activeIndex + 1)
  }

  return (
    <TeamListWrapper>
      <AnimateSplitText>
        <Heading1>{title}</Heading1>
      </AnimateSplitText>
      <Spacer size={[64, 72]} />

      <Grid>
        {React.Children.toArray(
          members.map((member, memberIndex) => {
            return (
              <GridItem tabletL={2} tabletLStart={memberIndex % 5 ? 0 : 2}>
                <TeamMember index={memberIndex} activeIndex={activeIndex}>
                  {member.resource && (
                    <TeamImage>
                      <AnimateFadeIn>
                        <GatsbyImage
                          image={member.resource.gatsbyImageData}
                          alt={member.resource.description}
                        />
                      </AnimateFadeIn>
                    </TeamImage>
                  )}
                  <TeamContent>
                    <AnimateSplitText>
                      <Heading3>{member.text}</Heading3>
                    </AnimateSplitText>
                    <Spacer size={4} />
                    <AnimateSplitText>
                      <TextBodySmall>{member.jobTitle}</TextBodySmall>
                    </AnimateSplitText>
                    <Spacer size={32} />
                  </TeamContent>
                </TeamMember>
              </GridItem>
            )
          })
        )}
      </Grid>

      {blockLength > 1 && (
        <CarouselControls>
          <IconButton
            onClick={previous}
            label="Previous Item"
            fromDirection="left"
            disabled={activeIndex === 0}
          >
            <IconCarouselArrow />
          </IconButton>
          <IconButton
            onClick={next}
            label="Next Item"
            fromDirection="left"
            disabled={activeIndex === blockLength - 1}
          >
            <IconCarouselArrow />
          </IconButton>
        </CarouselControls>
      )}
    </TeamListWrapper>
  )
}

Team.propTypes = {
  members: PropTypes.array,
}

export default Team
