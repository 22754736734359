import React from 'react'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { Heading3 } from '@components/TextStyles'
import { JumpToWrapper, JumpToButtons } from './index.style'
import Spacer from '@components/Spacer'
import Button from '@components/Button'
import IconArrowDown from '@svgs/IconArrowDown'
import { useStore } from '@Store/index'
import { anchorScroll } from '@utils'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'

const JumpTo = ({ isTeamFilled }) => {
  const [store] = useStore()
  const { smoothScroll } = store

  const handleJumpTo = id => {
    if (smoothScroll) {
      anchorScroll(smoothScroll, `#${id}`, window.innerWidth > 1024 ? 148 : 96)
    }
  }

  return (
    <JumpToWrapper>
      <Container>
        <Grid>
          <GridItem>
            <AnimateFadeIn delay={0.2}>
              <Heading3>Jump to:</Heading3>

              <Spacer size={24} />

              <JumpToButtons>
                <Button
                  type="button"
                  iconRight={<IconArrowDown />}
                  iconOrientation="vertical"
                  onClick={() => handleJumpTo('university')}
                >
                  Royal Caribbean University
                </Button>
                <Button
                  type="button"
                  iconRight={<IconArrowDown />}
                  iconOrientation="vertical"
                  onClick={() => handleJumpTo('resources')}
                >
                  RCU Resources
                </Button>
                <Button
                  type="button"
                  iconRight={<IconArrowDown />}
                  iconOrientation="vertical"
                  onClick={() => handleJumpTo('talks-webcasts')}
                >
                  Webcasts
                </Button>
                <Button
                  type="button"
                  iconRight={<IconArrowDown />}
                  iconOrientation="vertical"
                  onClick={() => handleJumpTo('team-contact')}
                >
                  {isTeamFilled ? 'Meet the Team' : 'Contact'}
                </Button>
              </JumpToButtons>
            </AnimateFadeIn>
          </GridItem>
        </Grid>
      </Container>

      <Spacer size={[72, 120]} />
    </JumpToWrapper>
  )
}

export default JumpTo
