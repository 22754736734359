import styled from 'styled-components'
import { ButtonContent } from '@components/Button/index.style'
import { mq } from '@styles/vars/media-queries.style'

export const JumpToWrapper = styled.div`
  text-align: center;
`

export const JumpToButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  text-align: center;

  ${mq.tabletL} {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.4rem;
  }

  ${mq.tabletLMax} {
    ${ButtonContent} {
      width: 100%;
      padding: 0 0.8rem;
      justify-content: space-between;
    }
  }
`
