import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RichText from '@components/RichText'
import Spacer from '@components/Spacer'

import { ContactWrapper } from './index.style'

const Contact = ({ content }) => {
  return (
    <ContactWrapper>
      <Grid>
        <GridItem desk={12}>
          <RichText content={content} />
          <Spacer size={24} />
        </GridItem>
      </Grid>
    </ContactWrapper>
  )
}

Contact.propTypes = {
  content: PropTypes.object,
}

export default Contact
