import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'

export const TeamContactWrapper = styled.div`
  ${clamp('margin-bottom', 30, 36)};
  ${clamp('padding-top', 32, 120)};
  ${clamp('padding-bottom', 48, 120)};
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  background: ${colors.light};
  ${clamp('border-radius', 12, 24)};
  overflow: hidden;

  ${mq.tabletL} {
    margin-top: -6.4rem;
  }
`
