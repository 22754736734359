import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { colors } from '@styles/vars/colors.style'
import { AnimateImageMain } from '@components/animation/AnimateImage/index.style'

export const ArticlesLinksWrapper = styled.div`
  border-top: 0.1rem solid ${colors.darkblue};

  ${mq.tabletL} {
    border-top: none;
  }
`

export const ArticlesTitleWrapper = styled.div`
  h2 {
    max-width: 20ch;
  }
`

export const ArticlesLinksList = styled.div`
  display: flex;
  margin-bottom: 4rem;
  flex-direction: column;
  gap: 1.6rem;

  ${mq.tabletL} {
    margin-bottom: 0;
  }
`

export const ArticlesArticleImageWrapper = styled.div`
  &:hover {
    ${AnimateImageMain} {
      transform: scale(1.03);
    }
  }
`

export const ArticlesArticleTitleWrapper = styled.div`
  max-width: 51.7rem;
`
