import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import TitleContent from '@components/TitleContent'
import RichFeaturedLinks from '@components/RichFeaturedLinks'
import ArticlesLinks from '@components/ArticlesLinks'
import Container from '@components/Container'
import TeamContact from '@components/Training/TeamContact'
import JumpTo from '@components/Training/JumpTo'
import { Heading1 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import AnimateSplitText from '@components/animation/AnimateSplitText'

const TrainingPage = ({ data, location }) => {
  const {
    slug,
    seo,
    intro,
    featuredTrainingTitle,
    featuredTrainingLink,
    universityResourcesTitle,
    universityResourcesLinks,
    universityResourcesContent,
    trainingWebcastsTitle,
    trainingWebcastsContent,
    team,
    contactInformation,
  } = data.contentfulTraining

  return (
    <>
      <Seo data={seo} slug={slug} />
      <TitleContent
        title={intro.title}
        content={intro.content}
        alignment={intro.alignmentDesktop}
        largeTitle={true}
        center
      />
      <JumpTo isTeamFilled={team.length > 0} />

      {featuredTrainingTitle && (
        <Container>
          <Grid>
            <GridItem tabletL={4}>
              <Heading1>
                <AnimateSplitText delay={0.4}>
                  {featuredTrainingTitle}
                </AnimateSplitText>
              </Heading1>
            </GridItem>
          </Grid>

          <Spacer size={[24, 48]} />
        </Container>
      )}
      <RichFeaturedLinks
        blocks={[featuredTrainingLink]}
        compact={true}
        inset={false}
        hugContent={true}
        buttonSize="small"
        id="university"
      />
      <ArticlesLinks
        title={universityResourcesTitle}
        links={universityResourcesLinks}
        articles={universityResourcesContent}
        id="resources"
      />
      <RichFeaturedLinks
        heading={trainingWebcastsTitle}
        blocks={trainingWebcastsContent}
        inset={false}
        id="talks-webcasts"
      />
      <TeamContact team={team} contactInformation={contactInformation} />

      <Footer location={location} />
    </>
  )
}

export default TrainingPage

export const pageQuery = graphql`
  query TrainingPage {
    contentfulTraining {
      slug
      seo {
        ...SEO
      }
      intro {
        ...TitleContentBlock
      }
      featuredTrainingTitle
      featuredTrainingLink {
        ...ImageContentBlock
      }
      universityResourcesTitle
      universityResourcesContent {
        ...ImageContentBlock
      }
      trainingWebcastsTitle
      trainingWebcastsContent {
        ...ImageContentBlock
      }
      team {
        text
        resource {
          gatsbyImageData(width: 568, height: 568)
          description
        }
        jobTitle
      }
      contactInformation {
        raw
      }
    }
  }
`
