import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import {
  ArticlesLinksWrapper,
  ArticlesTitleWrapper,
  ArticlesLinksList,
  ArticlesArticleImageWrapper,
  ArticlesArticleTitleWrapper,
} from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { Heading1 } from '@components/TextStyles'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import Link from '@components/Link'
import { ImageWrapper } from '@components/ImageContent/index.style'
import { Label } from '@components/TextStyles'
import RoundedImage from '@components/RoundedImage'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateImage from '@components/animation/AnimateImage'
import RichText from '@components/RichText'

const ArticlesLinks = ({ title, links, articles, id }) => {
  return (
    <Container>
      <ArticlesLinksWrapper id={id}>
        <Spacer size={[72, 96]} />

        <Grid>
          <GridItem tabletL={4}>
            <ArticlesTitleWrapper>
              {title && <Heading1 as="h2">{title}</Heading1>}

              {links?.length > 0 && (
                <>
                  {title && <Spacer size={[32, 45]} />}

                  <ArticlesLinksList>
                    {React.Children.toArray(
                      links.map(link =>
                        !link.url ? (
                          <AnimateFadeIn>
                            <Link page={link}>{link.title}</Link>
                          </AnimateFadeIn>
                        ) : (
                          <AnimateFadeIn>
                            <Link iconOrientation="vertical" url={link.url}>
                              {link.text}
                            </Link>
                          </AnimateFadeIn>
                        )
                      )
                    )}
                  </ArticlesLinksList>
                </>
              )}
            </ArticlesTitleWrapper>
          </GridItem>
          <GridItem tabletL={8}>
            <Grid>
              {articles?.length > 0 &&
                React.Children.toArray(
                  articles.map(article => (
                    <GridItem tabletL={4}>
                      <ArticlesArticleImageWrapper>
                        <ImageWrapper
                          gradient={article.imageGradient}
                          label={article.imageLabel}
                        >
                          {article.imageLabel && (
                            <Label color={article.textColor} dot>
                              {article.imageLabel}
                            </Label>
                          )}
                          <RoundedImage animate>
                            <AnimateImage hover={false}>
                              <GatsbyImage
                                image={article.imageLandscape.gatsbyImageData}
                                alt={article.image.description}
                              />
                            </AnimateImage>
                          </RoundedImage>
                        </ImageWrapper>
                      </ArticlesArticleImageWrapper>

                      <Spacer size={36} />

                      <ArticlesArticleTitleWrapper>
                        <RichText
                          content={article.content}
                          headingSize="small"
                          paragraphSize="small"
                          buttonSize="small"
                        />
                      </ArticlesArticleTitleWrapper>

                      <Spacer size={[0, 24]} />
                    </GridItem>
                  ))
                )}
            </Grid>
          </GridItem>
        </Grid>

        <Spacer size={[72, 104]} />
      </ArticlesLinksWrapper>
    </Container>
  )
}

ArticlesLinks.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array,
  articles: PropTypes.array,
}

export default ArticlesLinks
