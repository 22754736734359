import React from 'react'
import PropTypes from 'prop-types'
import Team from '../Team'
import Contact from '../Contact'
import Container from '@components/Container'
import { TeamContactWrapper } from './index.style'

const TeamContact = ({ team, contactInformation }) => {
  return (
    <Container>
      <TeamContactWrapper id="team-contact">
        {team && <Team members={team} title="Meet the team" />}
        <Contact content={contactInformation} />
      </TeamContactWrapper>
    </Container>
  )
}

TeamContact.propTypes = {
  team: PropTypes.array,
  contactInformation: PropTypes.object,
}

export default TeamContact
