import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'
import { GridMain } from '@components/Grid/index.style'
import { GridItemMain } from '@components/GridItem/index.style'
import { IconButtonMain } from '@components/IconButton/index.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

export const TeamListWrapper = styled.div`
  ${clamp('padding-bottom', 28, 48)};
  position: relative;
  text-align: center;
  /* display: none; */

  ${mq.tabletL} {
    display: block;
  }

  ${mq.tabletLMax} {
    ${GridMain} {
      grid-template-columns: auto;
    }

    ${GridItemMain} {
      grid-area: 1 / 1;
    }
  }
`

export const TeamMember = styled.div`
  max-width: 20rem;
  margin: 0 auto;

  ${mq.tabletL} {
    max-width: none;
  }

  ${mq.tabletLMax} {
    padding: 0 0.6rem;
    transform: ${({ index, activeIndex }) =>
      `translateX(${(index - activeIndex) * 100}%)`};
    transition: transform 1s ${cubicBezier.inOut};
  }
`

export const TeamImage = styled.div`
  ${clamp('border-radius', 1000, 1000)};
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: block;
  margin: 0 auto;
  text-align: center;
  max-width: 16rem;

  ${mq.tabletL} {
    max-width: 30rem;
  }
`

export const TeamContent = styled.div`
  text-align: center;
  margin-top: 1rem;
`

export const CarouselControls = styled.div`
  margin-bottom: 6.4rem;
  text-align: center;

  ${mq.tabletL} {
    display: none;
  }

  ${IconButtonMain}:nth-child(1) {
    margin-right: 1.2rem;
    transform: rotateZ(180deg);
  }

  svg {
    ${clamp('height', 14, 16)};
    ${clamp('width', 14, 16)};
  }
`
